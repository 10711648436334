







import {
  defineComponent,
  computed,
  onMounted,
  useContext,
  onServerPrefetch
} from '@nuxtjs/composition-api';
import { mapGetters } from 'vuex';
import {
  useCurrentUser,
  useWishlist,
  useTranslations
} from '@vue-storefront/novulo';

import '@storefront-ui/vue/styles.scss';

const CUSTOM_PAGE_LAYOUT = {
  checkout: 'checkout',
  registration: 'checkout'
};

const PAGES_MAP = {
  pdp: 'PagesPDP',
  plp: 'PagesPLP',
  cms: 'PagesCmsPage',
  stores: 'PagesStores',
  store: 'PagesStore',
  registration: 'PagesRegistration',
  cart: 'PagesCart',
  checkout: 'PagesCheckout',
  'thank-you': 'PagesThankYou'
};

export default defineComponent({
  layout(context) {
    const pageType = context.store.getters['page/getPageType'];
    return CUSTOM_PAGE_LAYOUT[pageType] || 'default';
  },
  computed: {
    ...mapGetters('page', ['getPageType', 'getUrlInfo'])
  },
  head() {
    // set GTM data for external script (init-gtm.js)
    return {
      meta: [
        {
          id: 'gtm-init-data',
          name: 'gtm-init-data',
          content: JSON.stringify(this.gtmData)
        }
      ]
    };
  },
  setup() {
    const context = useContext();

    const { getCurrentUser } = useCurrentUser();
    const { getWishlist } = useWishlist();
    const { getTranslations } = useTranslations();

    const gtmData = computed(() => context.store.state['gtm-init']);

    onServerPrefetch(async () => {
      await getTranslations();
    });

    onMounted(async () => {
      await getCurrentUser();
      getWishlist();
    });

    return {
      PAGES_MAP,
      gtmData
    };
  }
});
